import React, { Suspense } from 'react';
import './App.css';
import sketch from './sketch';
import p5 from 'p5';
import Confetti from "react-confetti";
const Home = React.lazy(() => import('./Home'));

const TEXT = 'Happy Birthday Chloee';

const App = () => {
  const canvasRef = React.useRef(null)
  React.useEffect(() => {
    const canvasDivElement = canvasRef.current;

    // NOTE: If you change TEXT value to something else, you'll need to make changes in sketch.js as well to make it work
    // See my comments in sketch.js
    new p5(sketch(canvasDivElement, TEXT), canvasDivElement);
  });




  return (
    <div className="main">
      <Confetti />
      <div ref={canvasRef} />
      <Suspense fallback={<div>Loading...</div>} >
        <Home/>
      </Suspense>
    </div >
  )
  
}

export default App;
